export default {
	data() {
		return {
			loading: false,
			versionUedact: {},
			limitUpload: 1,
			fileTemp: null,
			showUpload: false,
			//创建版本验证
			UedactVersionRules: {
				abstracts: [{
					required: true,
					message: '请输入摘要',
					trigger: 'blur'
				}, {
					min: 3,
					max: 100,
					message: '摘要长度在 3 到 100 个字符',
					trigger: 'blur'
				}],
				versionNumber: [{
					required: true,
					message: '请输入版本号',
					trigger: 'blur'
				}, {
					pattern: new RegExp(/^[vV][1-9](\d)*\.(\d)+\.(\d)+$/, 'g'),
					message: '版本号需要类似v1.0.0格式',
					trigger: 'blur'
				}],
				updateContent: [{
					required: true,
					message: '请输入更新内容',
					trigger: 'blur'
				}, {
					min: 20,
					message: '更新内容长度至少需要20个字符',
					trigger: 'blur'
				}],
				onlineTime: [{
					required: true,
					message: '请选择上线日期',
					trigger: 'blur'
				}, {
					min: 10,
					max: 10,
					message: '上线日期为20个字符',
					trigger: 'blur'
				}]
			},
		}
	},
	created() {
		this.versionUedact = this.$route.query.versionUedact;
	},
	methods: {
		//编辑版本
		submitForm(versionUedact) {
			this.$refs[versionUedact].validate((valid) => {
				if (valid) {
					if(this.versionUedact.type==2){
						this.versionUedact.fileName="";
						this.versionUedact.url="";
					}
					this.$axios({
						method: "post",
						url: "/api/cgm/web/versionPublish/update", // 接口地址
						data: this.versionUedact
					}).then(res => {
						if (res.data.code === 200) {
							this.$router.push({
								path: 'version-list'
							});
							this.$message({
								message: "修改成功",
								type: "succeed",
							});
						} else {
							this.$message({
								message: "操作失败",
								type: "error",
							});
						}
					}).catch(error => {
						console.log(error);
						//this.$message.error("请求失败");
					});
				}
			})
		},
		handleChange(file) {
			this.showUpload = true;
			this.fileTemp = file.raw;
		},
		cancelUpload() {
			this.showUpload = false;
			this.fileTemp = null;
		},
		confirmUpload() {
			if (this.fileTemp) {
				this.showUpload = false;
				this.loading = true;
				console.log(this.fileTemp.type)
				if (this.fileTemp.type == "application/vnd.android.package-archive") {

					let fileName = this.fileTemp.name;
					let file = this.fileTemp;
					const formData = new FormData();
					formData.append('file', file);

					this.$axios({
						method: "post",
						async:false,
						url: "/api/cgm/web/file/uploadFile?catalogue=appDownload&fileName=" +
							fileName, // 接口地址
						// 请求头，发送FormData格式的数据，必须是 这种请求头。
						headers: {
							"Content-Type": "application/form-data"
						},
						data: formData
					}).then(res => {
						if (res.data.code === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
							});
							this.versionUedact.filePath = res.data.data.url;
							this.versionUedact.url = res.data.data.filePath;
							this.versionUedact.fileName=fileName;
						} else {
							this.$message({
								message: "操作失败",
								type: "error",
							});
						}
						this.loading = false;
					}).catch(error => {
						console.log(error);
						this.loading = false;
						//this.$message.error("请求失败");
					});
				} else {
					this.loading = false;
					this.$message({
						type: 'warning',
						message: '附件格式错误，请重新上传！'
					})
				}
			} else {
				this.$message({
					type: 'warning',
					message: '请上传Apk更新包！'
				})
			}
		}
	}
}
