import { render, staticRenderFns } from "./RedactVersion.vue?vue&type=template&id=29177335&scoped=true&"
import script from "./RedactVersion.js?vue&type=script&lang=js&"
export * from "./RedactVersion.js?vue&type=script&lang=js&"
import style0 from "./RedactVersion.vue?vue&type=style&index=0&id=29177335&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29177335",
  null
  
)

export default component.exports